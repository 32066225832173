@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
  transition: ease 0.2s;
  &:hover{
    opacity: 0.5;
  }
}

* {
  box-sizing: border-box;
}

/* Loader */

.loader {
  border: 10px solid var(--color-bg);
  border-top: 10px solid var(--color-blue);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;700&family=Rationale&display=swap');
body{
  font-family: 'Noto Sans JP',-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.fontMain {
  font-family: 'Rationale', sans-serif;
}

/*==レイアウト
================================*/
.layout {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1216px;
  margin: 0 auto;
  padding-top: 60px;
  .main-body {
    width: calc(100% - 80px - 240px);
  }
  .side {
    width: 240px;
    padding-bottom: 90px;
  }
  &.reverse{
    flex-direction: row-reverse;
  }
}
/*==見出し
================================*/
.heading{
  .title{
    font-size: 32px;
  }
  .sub-title{
    font-size: 18px;
  }
}
.side {
  .heading{
    .title{
      font-size: 24px;
    }
    .sub-title{
      font-size: 12px;
    }
  }
}
/*==ボタン
================================*/
.linkBtn {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 12px;
  color: #787878;
  line-height: 1;
  &::before,
  &::after {
    content: '';
    display: block;
    height: 1px;
    background-color: #787878;
  }
  &::after {
    width: 48px;
    margin-left: 1em;
    transform: translateY(-2px);
  }
  &::before {
    position: absolute;
    right: 0;
    bottom: 2px;
    width: 7px;
    transform-origin: bottom right;
    transform: rotate(45deg);
  }
  &.right {
    display: inline-flex;
  }
  &.bg {
    display: inline-flex;
    padding: 16px;
    color: #fff;
    &::before,
    &::after {
      background-color: #fff;
    }
    &::before {
      right: 16px;
      bottom: 18px;
    }
  }
}
/*==ノートリスト
================================*/
.noteCard-list{
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  &>*{
    width: calc((100% - 16px * 2) / 3);
    margin-right: 16px;
    &:nth-child(3n){
      margin-right: 0;
    }
    &:nth-child(n+4){
      margin-top: 16px;
    }
  }
}
.noteCard {
  a {
    display: block;
  }
  .category {
    display: inline-block;
    padding: 0.5em 0.7em;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    background-color: #ccc;
  }
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 84px;
    padding: 1em;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.618;
    text-align: justify;
    background-color: #f1f1f1;
    border-left: solid 4px #ccc;
  }
  .tagList {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0.5em 1em;
    & > * {
      margin-top: 0.3em;
      margin-right: 0.5em;
      font-size: 13px;
    }
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 0.5em;
    border-top: solid 1px #e8e8e8;
    font-size: 10px;
    color: #787878;
  }
  &.case{
    .category {
      background-color: #69DEAD;
    }
    .title {
      border-left: solid 4px #69DEAD;
    }
  }
  &.proposal{
    .category {
      background-color: #ffc700;
    }
    .title {
      border-left: solid 4px #ffc700;
    }
  }
}
/*==企業リスト
================================*/
.conpanyCrad-list{
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  &>*{
    width: calc((100% - 16px * 3) / 4);
    margin-right: 16px;
    &:nth-child(4n){
      margin-right: 0;
    }
    &:nth-child(n+5){
      margin-top: 16px;
    }
  }
}
.conpanyCrad-item{
  overflow: hidden;
  border: solid 3px #3450A4;
  border-radius: 16px;
  a{
    display: block;

  }
  .img{
    position: relative;
    width: 100%;
    height: 120px;
  }
  .info{
    padding: 0.75em 1em;
    .company-name{
      font-size: 14px;
      font-weight: bold;
    }
    .category{
      margin-top: 0.2em;
      font-size: 12px;
    }
  }
}